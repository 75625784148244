import { createWithEqualityFn } from "zustand/traditional";

const useLineupStore = createWithEqualityFn(
  (set, get) => ({
    players: null,
    setPlayers: (players) => {
      set({ players: players });
    },

    formation: null,
    setFormation: (formation) => {
      set({ formation: formation });
    },

    bonusEligible: null,
    setBonusEligible: (bonusEligible) => {
      set({ bonusEligible: bonusEligible });
    },

    selectedPlayerId: null,
    setSelectedPlayerId: (selectedPlayerId) => {
      set({ selectedPlayerId: selectedPlayerId });
    },

    selectedSwapPlayerId: null,
    setSelectedSwapPlayerId: (selectedSwapPlayerId) => {
      set({ selectedSwapPlayerId: selectedSwapPlayerId });
    },

    selectedSwapPositionAbbr: null,
    setSelectedSwapPositionAbbr: (selectedSwapPositionAbbr) => {
      set({ selectedSwapPositionAbbr: selectedSwapPositionAbbr });
    },

    selectedSwapPositionType: null,
    setSelectedSwapPositionType: (selectedSwapPositionType) => {
      set({ selectedSwapPositionType: selectedSwapPositionType });
    },

    selectedSwapListOrder: null,
    setSelectedSwapListOrder: (selectedSwapListOrder) => {
      set({ selectedSwapListOrder: selectedSwapListOrder });
    },

    dropPlayerIds: [],
    setDropPlayerIds: (dropPlayerIds) => {
      set({ dropPlayerIds: dropPlayerIds });
    },

    dropPositionAbbrs: [],
    setDropPositionAbbrs: (dropPositionAbbrs) => {
      set({ dropPositionAbbrs: dropPositionAbbrs });
    },

    isSwapping: false,
    setIsSwapping: (isSwapping) => {
      set({ isSwapping: isSwapping });
    },

    captainId: null,
    setCaptainId: (captainId) => {
      set({ captainId: captainId });
    },

    viceId: null,
    setViceId: (viceId) => {
      set({ viceId: viceId });
    },

    deputyId: null,
    setDeputyId: (deputyId) => {
      set({ deputyId: deputyId });
    },

    newCaptainId: null,
    setNewCaptainId: (captainId) => {
      set({ newCaptainId: captainId });
    },

    newViceId: null,
    setNewViceId: (viceId) => {
      set({ newViceId: viceId });
    },

    newDeputyId: null,
    setNewDeputyId: (deputyId) => {
      set({ newDeputyId: deputyId });
    },

    numCaptains: 0,
    setNumCaptains: (numCaptains) => {
      set({ numCaptains: numCaptains });
    },
  }),
  Object.is,
);

export default useLineupStore;
