export function getFormationPosById(abbr) {
  return formationPositions.find((pos) => pos.abbr === abbr);
}

export function getFormationPosByAbbr(abbr) {
  return formationPositions.find((pos) => pos.abbr === abbr);
}

export const formationPositions = [
  {
    value: "KEYD",
    fullName: "Key Defender",
    abbr: "KEYD",
    numStarters: 1,
    numStartersInKeyLeague: null,
    numEmergencies: 1,
    displayOrder: 10,
    eligiblePlayerPos: ["KEYD"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: false,
  },
  {
    value: "DEF",
    fullName: "Defender",
    abbr: "DEF",
    numStarters: 4,
    numStartersInKeyLeague: 3,
    numEmergencies: 1,
    displayOrder: 30,
    eligiblePlayerPos: ["DEF"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "MID",
    fullName: "Midfielder",
    abbr: "MID",
    numStarters: 5,
    numStartersInKeyLeague: null,
    numEmergencies: 1,
    displayOrder: 60,
    eligiblePlayerPos: ["MID"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "RUC",
    fullName: "Ruck",
    abbr: "RUC",
    numStarters: 1,
    numStartersInKeyLeague: null,
    numEmergencies: 1,
    displayOrder: 80,
    eligiblePlayerPos: ["RUC"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "FWD",
    fullName: "Forward",
    abbr: "FWD",
    numStarters: 4,
    numStartersInKeyLeague: 3,
    numEmergencies: 1,
    displayOrder: 110,
    eligiblePlayerPos: ["FWD"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "KEYF",
    fullName: "Key Forward",
    abbr: "KEYF",
    numStarters: 1,
    numStartersInKeyLeague: null,
    numEmergencies: 1,
    displayOrder: 130,
    eligiblePlayerPos: ["KEYF"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: false,
  },
  {
    value: "UTL",
    fullName: "Utility",
    abbr: "UTL",
    numStarters: 1,
    numStartersInKeyLeague: null,
    numEmergencies: 1,
    displayOrder: 300,
    eligiblePlayerPos: ["KEYD", "DEF", "MID", "RUC", "FWD", "KEYF"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "D/F",
    fullName: "Def/Fwd",
    abbr: "D/F",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 170,
    eligiblePlayerPos: ["DEF", "FWD"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "D/M",
    fullName: "Def/Mid",
    abbr: "D/M",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 50,
    eligiblePlayerPos: ["DEF", "MID"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "D/R",
    fullName: "Def/Ruck",
    abbr: "D/R",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 40,
    eligiblePlayerPos: ["DEF", "RUC"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: false,
    isNonKeyPosLeague: true,
  },
  {
    value: "M/R",
    fullName: "Mid/Ruc",
    abbr: "M/R",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 70,
    eligiblePlayerPos: ["MID", "RUC"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "M/F",
    fullName: "Mid/Fwd",
    abbr: "M/F",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 100,
    eligiblePlayerPos: ["MID", "FWD"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "R/F",
    fullName: "Ruc/Fwd",
    abbr: "R/F",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 90,
    eligiblePlayerPos: ["RUC", "FWD"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: false,
    isNonKeyPosLeague: true,
  },
  {
    value: "R/KF",
    fullName: "Ruc/KeyF",
    abbr: "R/KF",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 140,
    eligiblePlayerPos: ["RUC", "KEYF"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: false,
  },
  {
    value: "KD/R",
    fullName: "KeyD/Ruc",
    abbr: "KD/R",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 150,
    eligiblePlayerPos: ["KEYD", "RUC"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: false,
  },
  {
    value: "KD/D",
    fullName: "KeyD/Def",
    abbr: "KD/D",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 20,
    eligiblePlayerPos: ["KEYD", "DEF"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: false,
  },
  {
    value: "KF/F",
    fullName: "KeyF/Fwd",
    abbr: "KF/F",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 120,
    eligiblePlayerPos: ["FWD", "KEYF"],
    isDefault: true,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: false,
  },
  {
    value: "KF/KD",
    fullName: "KeyF/KeyD",
    abbr: "KF/KD",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 160,
    eligiblePlayerPos: ["KEYD", "KEYF"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: false,
  },
  {
    value: "D/M/F",
    fullName: "Def/Mid/Fwd",
    abbr: "D/M/F",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 200,
    eligiblePlayerPos: ["KEYD", "DEF", "MID", "FWD", "KEYF"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: true,
    isNonKeyPosLeague: true,
  },
  {
    value: "BN",
    fullName: "Bench",
    abbr: "BN",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 900,
    eligiblePlayerPos: ["KEYD", "DEF", "MID", "RUC", "FWD", "KEYF"],
    isDefault: false,
    isBonus: false,
    isKeyPosLeague: false,
    isNonKeyPosLeague: false,
  },
  {
    value: "IL",
    fullName: "Injured",
    abbr: "IL",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 910,
    eligiblePlayerPos: ["KEYD", "DEF", "MID", "RUC", "FWD", "KEYF"],
    isDefault: false,
    isBonus: true,
    isKeyPosLeague: false,
    isNonKeyPosLeague: false,
  },
  {
    value: "RL",
    fullName: "Rookie",
    abbr: "RL",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 920,
    eligiblePlayerPos: ["KEYD", "DEF", "MID", "RUC", "FWD", "KEYF"],
    isDefault: false,
    isBonus: true,
    isKeyPosLeague: false,
    isNonKeyPosLeague: false,
  },
  {
    value: "VL",
    fullName: "Veteran",
    abbr: "VL",
    numStarters: 0,
    numStartersInKeyLeague: null,
    numEmergencies: 0,
    displayOrder: 930,
    eligiblePlayerPos: ["KEYD", "DEF", "MID", "RUC", "FWD", "KEYF"],
    isDefault: false,
    isBonus: true,
    isKeyPosLeague: false,
    isNonKeyPosLeague: false,
  },
];
