import { Controller } from "@hotwired/stimulus";
import useLineupStore from "../react/draft/lib/store/LineupStore";
import { getFormationPosByAbbr } from "../react/global/lib/utils/formationPositions";
import { getCurrentRole } from "../react/draft/components/buttons/CaptainUtils";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    lineup: Object,
    formation: Object,
  };

  connect() {
    // When the controller connects, show the alert with the data
    this.showLineupData();

    this.element.addEventListener(
      "lineupEvent",
      this.handleLineupEvent.bind(this),
    );

    useLineupStore.getState().setIsSwapping(false);

    const turboFrame = document.getElementById("lineup-frame");
    if (turboFrame) {
      turboFrame.addEventListener(
        "turbo:frame-load",
        this.handleFrameLoad.bind(this),
      );
    }
  }

  disconnect() {
    super.disconnect();

    this.clearStores();
  }

  showLineupData() {
    // Access the data through the values
    const props = {
      lineup: this.lineupValue,
      formation: this.formationValue,
    };
    console.log(props);

    const players = this.lineupValue.players;

    const lineupStore = useLineupStore.getState();

    lineupStore.setPlayers(players);
    lineupStore.setFormation(this.formationValue);
    lineupStore.setBonusEligible(this.lineupValue.eligible);
    lineupStore.setCaptainId(this.lineupValue.captains?.captain);
    lineupStore.setViceId(this.lineupValue.captains?.vice);
    lineupStore.setDeputyId(this.lineupValue.captains?.deputy);
    lineupStore.setNumCaptains(this.lineupValue.captains.limit);
    lineupStore.setNewCaptainId(this.lineupValue.captains?.captain);
    lineupStore.setNewViceId(this.lineupValue.captains?.vice);
    lineupStore.setNewDeputyId(this.lineupValue.captains?.deputy);
  }

  async handleLineupEvent(event) {
    const lineupStore = useLineupStore.getState();

    const selectedPlayerId = lineupStore.selectedPlayerId;
    const swapPlayerId = lineupStore.selectedSwapPlayerId;
    const swapPositionAbbr = lineupStore.selectedSwapPositionAbbr;
    const swapPositionType = lineupStore.selectedSwapPositionType;
    const swapListOrder = lineupStore.selectedSwapListOrder;
    const bonusEligible = lineupStore.bonusEligible;

    // Add check for swap player's captain role
    const swapPlayerCaptainRole = getCurrentRole(
      swapPlayerId,
      lineupStore.captainId,
      lineupStore.viceId,
      lineupStore.deputyId,
    );

    const selectedPlayerCaptainRole = getCurrentRole(
      selectedPlayerId,
      lineupStore.captainId,
      lineupStore.viceId,
      lineupStore.deputyId,
    );

    // console.log(
    //   "Selected player:",
    //   selectedPlayerId,
    //   "Role:",
    //   selectedPlayerCaptainRole,
    //   "Swap player:",
    //   swapPlayerId,
    //   "Role:",
    //   swapPlayerCaptainRole,
    // );

    // Lineup update
    const players = this.lineupValue.players;

    const selectedPlayer = players.find(
      (player) => player.id === selectedPlayerId,
    );
    const originalFormationPos = selectedPlayer
      ? getFormationPosByAbbr(selectedPlayer.selectedPos)
      : null;

    const swapPlayer = players.find((player) => player.id === swapPlayerId);

    // console.log("Moved to ", swapPositionAbbr, swapPositionType, swapPlayer);

    const lineupMap = players.map((player) => {
      // Go through the original lineup and update the original player and swap player
      if (player.id === selectedPlayerId) {
        // Moving to starter position and swap player is a captain
        if (swapPositionType === "starter" && swapPlayerCaptainRole) {
          // console.log("Moving to captain position, taking over role");
          if (swapPlayerCaptainRole === "C") {
            lineupStore.setNewCaptainId(selectedPlayerId);
          } else if (swapPlayerCaptainRole === "VC") {
            lineupStore.setNewViceId(selectedPlayerId);
          } else if (swapPlayerCaptainRole === "DVC") {
            lineupStore.setNewDeputyId(selectedPlayerId);
          }
        }

        // Moving from starter and is a captain
        if (swapPositionType !== "starter" && selectedPlayerCaptainRole) {
          // console.log("No longer captain eligible, replace with swap player");
          if (selectedPlayerCaptainRole === "C") {
            lineupStore.setNewCaptainId(swapPlayerId);
          } else if (selectedPlayerCaptainRole === "VC") {
            lineupStore.setNewViceId(swapPlayerId);
          } else if (selectedPlayerCaptainRole === "DVC") {
            lineupStore.setNewDeputyId(swapPlayerId);
          }
        }

        return {
          id: player.id,
          pos: swapPositionAbbr,
          type: swapPositionType,
          listOrder: swapListOrder,
        };
      } else if (player.id === swapPlayerId) {
        // Rest of the swap logic...
        if (
          (selectedPlayer.selectedPos === "IL" &&
            bonusEligible.injury.includes(player.id)) ||
          (selectedPlayer.selectedPos === "RL" &&
            bonusEligible.rookie.includes(player.id)) ||
          (selectedPlayer.selectedPos === "VL" &&
            bonusEligible.vet.includes(player.id))
        ) {
          return {
            id: player.id,
            pos: selectedPlayer.selectedPos,
            type: selectedPlayer.type,
            listOrder: selectedPlayer.listOrder,
          };
        } else if (
          !["IL", "RL", "VL"].includes(selectedPlayer.selectedPos) &&
          player.playerPos.some((p) =>
            originalFormationPos.eligiblePlayerPos.includes(p),
          )
        ) {
          return {
            id: player.id,
            pos: selectedPlayer.selectedPos,
            type: selectedPlayer.type,
            listOrder: selectedPlayer.listOrder,
          };
        } else {
          return {
            id: player.id,
            pos: "BN",
            type: "bench",
            listOrder: 99,
          };
        }
      }

      return {
        id: player.id,
        pos: player.selectedPos,
        type: player.type,
        listOrder: player.listOrder,
      };
    });

    const finalStore = useLineupStore.getState();

    const payload = {
      players: lineupMap,
      captain: finalStore.newCaptainId,
      vice: finalStore.newViceId,
      deputy: finalStore.newDeputyId,
    };
    // console.log("Payload:", payload);

    this.submitForm(payload);
  }

  submitForm(payload) {
    // Update the existing hidden input instead of creating a new one
    const hiddenField = this.formTarget.querySelector(
      "#lineup_json_form_newLineup",
    );
    hiddenField.value = JSON.stringify(payload);

    this.formTarget.requestSubmit();
  }

  handleFrameLoad(event) {
    // After form frame loads after submit, dispatch a custom event to close the bottom sheet
    const closeSheetEvent = new CustomEvent("closeSheet", { detail: {} });
    document.dispatchEvent(closeSheetEvent);

    this.clearStores();
  }

  clearStores() {
    useLineupStore.getState().setSelectedPlayerId(null);
    useLineupStore.getState().setSelectedSwapPlayerId(null);
    useLineupStore.getState().setSelectedSwapPositionAbbr(null);
    useLineupStore.getState().setSelectedSwapPositionType(null);
    useLineupStore.getState().setSelectedSwapListOrder(null);
    useLineupStore.getState().setDropPlayerIds([]);
    useLineupStore.getState().setDropPositionAbbrs([]);
  }
}
